import React, { FunctionComponent } from 'react';
import { Box, Flex, FlexProps, Heading, Stack, Text } from '@chakra-ui/react';

interface Props {
    title: string;
    subtitle?: string;
    align?: FlexProps['align'];
    justify?: FlexProps['justify'];
}

const FullCenterForm: FunctionComponent<Props> = ({
    children,
    title,
    subtitle,
    align,
    justify,
}) => (
    <Flex
        minH="calc(100vh - 120px)"
        align={align || 'flex-start'}
        justify={justify || 'center'}
    >
        <Stack spacing={8} mx="auto" maxW="lg">
            <Stack align="center" paddingTop={4}>
                <Heading fontSize="4xl">{title}</Heading>
                {subtitle && (
                    <Text fontSize="md" color="wedje2.300">
                        {subtitle}
                    </Text>
                )}
            </Stack>
            <Box
                p={8}
                maxWidth="500px"
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
                bg="backgrounds.300"
            >
                {children}
            </Box>
        </Stack>
    </Flex>
);

export default FullCenterForm;
