import React, { FunctionComponent } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import TwoColPage from '../global/TwoColPage';
import WedjeShareForm from './WedjeShareForm';
import IntroColumn from '../static/IntroColumn';

const WedjeShare: FunctionComponent = () => (
    <TwoColPage
        leftColumn={
            <Box
                minWidth="360px"
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
                bg="backgrounds.300"
                width="100%"
            >
                <Box paddingX={8} paddingTop={4} paddingBottom={6} minH={280}>
                    <WedjeShareForm />
                </Box>
            </Box>
        }
    >
        <Stack spacing={0} width="100%" alignItems="center" padding={4}>
            <IntroColumn />
        </Stack>
    </TwoColPage>
);

export default WedjeShare;
