import React, { FunctionComponent, useCallback, useState } from 'react';
import { ApiResult, User } from './types';
import { useLocalStorage } from './hooks/useLocalStorage';
import ApiHelper from './utils/ApiHelper';
import { LOCAL_STORAGE_TOKEN_KEY } from './constants';

export interface AuthProviderContextType {
    isLoading: boolean;
    isAuthenticated: boolean;
    user: User | null;
    setToken: (newToken: string) => void;
    token: string;
    logout: () => void;
}
export const AuthProviderContext = React.createContext<AuthProviderContextType>(
    {
        isLoading: true,
        isAuthenticated: false,
        user: null,
        setToken: () => {},
        token: '',
        logout: () => {},
    }
);

const AuthProvider: FunctionComponent = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    // Save token in state, for rerenders, and localstorage, for refreshes and later visits
    const [localToken, setLocalToken] = useLocalStorage(
        LOCAL_STORAGE_TOKEN_KEY,
        ''
    );
    const [stateToken, setStateToken] = useState<string>('');

    const logout = useCallback(() => {
        setUser(null);
        setIsAuthenticated(false);
        setStateToken('');
        setLocalToken('');
        ApiHelper.setToken('');
    }, [setLocalToken]);

    const handleMeResult = useCallback(
        (res: ApiResult | null) => {
            if (!res || !res.json) {
                logout();
            } else {
                const meUser: User = {
                    id: Number(res?.json?.id),
                    name: String(res?.json?.name),
                    email: String(res?.json?.email),
                };
                setUser(meUser);
                setIsAuthenticated(true);
            }
            setIsLoading(false);
        },
        [logout]
    );

    // Tries to log the user in with the supplied token. If the token is an empty string, logs the user out
    const setToken = useCallback(
        (newToken: string) => {
            ApiHelper.setToken(newToken);
            setLocalToken(newToken);
            setStateToken(newToken);

            if (newToken.length > 0) {
                ApiHelper.setToken(newToken);
                ApiHelper.getMe()
                    .then((res) => {
                        handleMeResult(res);
                        return true;
                    })
                    .catch((err: any) => {
                        console.warn('Me fetch error', err);
                        handleMeResult(null);
                    });
            } else {
                logout();
            }
        },
        [handleMeResult, logout, setLocalToken]
    );

    // Login from localstorage
    if (stateToken === '' && localToken.length > 0) {
        setIsLoading(true);
        setToken(localToken);
    }

    const authContext: AuthProviderContextType = {
        isLoading,
        isAuthenticated,
        user,
        setToken,
        token: stateToken,
        logout,
    };

    return (
        <>
            <AuthProviderContext.Provider value={authContext}>
                {children}
            </AuthProviderContext.Provider>
        </>
    );
};

export default AuthProvider;
