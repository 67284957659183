import React, { FunctionComponent, useContext } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Link,
    useToast,
} from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { Field, FieldProps, Form, Formik } from 'formik';
import ApiHelper from '../../utils/ApiHelper';
import FullCenterForm from '../global/FullCenterForm';
import { RouterLink } from '../../types';
import { AuthProviderContext } from '../../AuthProvider';

type FormValues = {
    email: string;
    password: string;
};

const initialValues: FormValues = {
    email: '',
    password: '',
};

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Vul een geldig e-mailadres in')
        .required('Vul je e-mailadres in'),
    password: yup.string().required('Vul je wachtwoord in'),
});

const LoginForm: FunctionComponent = () => {
    const toast = useToast();
    const { isAuthenticated, setToken } = useContext(AuthProviderContext);
    const referrer = document.referrer ?? '/overview';

    const submitHandler = (values: FormValues) =>
        ApiHelper.login(values.email, values.password)
            .then((res) => {
                if (res) {
                    if (res.status === 200) {
                        const token: string = String(res?.json?.token) || '';
                        if (token.length > 0) {
                            setToken(token);
                            toast({
                                title: `Welkom terug!`,
                                status: 'success',
                            });
                        } else {
                            toast({
                                title: 'Inloggen mislukt',
                                description:
                                    'Ongeldige gebruikernsaam / wachtwoord',
                                status: 'warning',
                            });
                        }
                    } else {
                        toast({
                            title: 'Inloggen mislukt',
                            description:
                                'Ongeldige gebruikernsaam / wachtwoord',
                            status: 'warning',
                        });
                    }
                } else {
                    toast({
                        title: 'Er gaat iets mis',
                        status: 'error',
                    });
                }
                return res;
            })
            .catch((error: string) => {
                toast({
                    title: 'Er gaat iets mis',
                    description: error.toString(),
                    status: 'error',
                });
            });

    if (isAuthenticated) {
        return <Redirect to={referrer} />;
    }

    return (
        <FullCenterForm title="Inloggen">
            <Formik
                initialValues={initialValues}
                onSubmit={submitHandler}
                validationSchema={validationSchema}
            >
                {(props) => (
                    <Form>
                        <Field name="email">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="email">
                                        E-mailadres
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="email"
                                        placeholder="test@test.com"
                                        size="lg"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="password">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                >
                                    <FormLabel htmlFor="password">
                                        Wachtwoord
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="password"
                                        placeholder="******"
                                        size="lg"
                                        type="password"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Button
                            isLoading={props.isSubmitting}
                            disabled={!(props.isValid && props.dirty)}
                            type="submit"
                            width="full"
                            mt={4}
                        >
                            {props.isSubmitting ? (
                                <CircularProgress
                                    isIndeterminate
                                    size="24px"
                                    color="wedje.500"
                                />
                            ) : (
                                'Inloggen'
                            )}
                        </Button>
                        <Box textAlign="center" paddingTop={3}>
                            <Link
                                color="wedje.500"
                                as={RouterLink}
                                to="/forgot-password"
                            >
                                Wachtwoord vergeten
                            </Link>
                        </Box>

                        <Box textAlign="center" paddingTop={5}>
                            <Link
                                color="wedje.500"
                                as={RouterLink}
                                to="/register"
                            >
                                Geen account? Klik hier om te registreren
                            </Link>
                        </Box>
                    </Form>
                )}
            </Formik>
        </FullCenterForm>
    );
};

export default LoginForm;
