import React, { FunctionComponent, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Link,
    Text,
    useToast,
} from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { Field, FieldProps, Form, Formik } from 'formik';
import ApiHelper from '../../utils/ApiHelper';
import FullCenterForm from '../global/FullCenterForm';
import { RouterLink } from '../../types';

type FormValues = {
    email: string;
    password: string;
    passwordConfirm: string;
};

const initialValues: FormValues = {
    email: '',
    password: '',
    passwordConfirm: '',
};

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Vul een geldig e-mailadres in')
        .required('Vul je e-mailadres in'),
    password: yup
        .string()
        .min(8, 'Wachtwoord is te kort, moet minimaal 8 karakters zijn')
        .required('Vul je wachtwoord in'),
    passwordConfirm: yup
        .string()
        .oneOf(
            [yup.ref('password'), null],
            'Wachtwoorden moeten hetzelfde zijn'
        ),
});

const PasswordResetForm: FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const toast = useToast();
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token') || '';
    ApiHelper.checkResetPasswordToken(token)
        .then((res) => {
            if (res && res.json?.message === 'ok') {
                setIsLoading(false);
                return true;
            }
            throw new Error('failed');
        })
        .catch(() => {
            toast({
                title: `Ongeldige link`,
                description:
                    'Probeer de link uit de e-mail handmatig te kopieren en plakken in de browser',
            });
        });

    const submitHandler = (values: FormValues) =>
        ApiHelper.resetPassword(token, values.email, values.password)
            .then((res) => {
                if (res && res.status === 200 && res.json?.message === 'ok') {
                    toast({
                        title: `Wachtwoord gewijzigd, je kunt nu inloggen met je nieuwe wachtwoord`,
                        status: 'success',
                    });
                }
                if (res) {
                    let error = '';
                    Object.values(res).forEach((value) => {
                        error = `${error} ${value}`;
                    });
                    toast({
                        title: `Er gaat iets mis`,
                        description: error,
                    });
                } else {
                    throw new Error('Ongeldig e-mailadres of wachtwoord');
                }
                return res;
            })
            .catch((error: string) => {
                toast({
                    title: 'Er gaat iets mis',
                    description: error.toString(),
                    status: 'error',
                });
            });

    if (ApiHelper.isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <FullCenterForm
            title="Wachtwoord wijzigen"
            subtitle={
                isLoading
                    ? 'Even geduld, we controleren de link'
                    : 'Vul een nieuw wachtwoord in'
            }
        >
            <Formik
                initialValues={initialValues}
                onSubmit={submitHandler}
                validationSchema={validationSchema}
            >
                {(props) => (
                    <Form>
                        <Field name="email">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="email">
                                        E-mailadres
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="email"
                                        placeholder="test@test.com"
                                        size="lg"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="password">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="password">
                                        Wachtwoord
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="password"
                                        placeholder="*********"
                                        size="lg"
                                        type="password"
                                        disabled={isLoading}
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="passwordConfirm">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                >
                                    <FormLabel htmlFor="passwordConfirm">
                                        Wachtwoord{' '}
                                        <Text
                                            as="span"
                                            color="wedje2.300"
                                            fontSize="sm"
                                        >
                                            (Ter controle)
                                        </Text>
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="passwordConfirm"
                                        placeholder="*********"
                                        size="lg"
                                        type="password"
                                        disabled={isLoading}
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Button
                            isLoading={props.isSubmitting}
                            disabled={
                                !(props.isValid && props.dirty) || isLoading
                            }
                            type="submit"
                            width="full"
                            mt={4}
                        >
                            {props.isSubmitting || isLoading ? (
                                <CircularProgress
                                    isIndeterminate
                                    size="24px"
                                    color="wedje.500"
                                />
                            ) : (
                                'Wijzig wachtwoord'
                            )}
                        </Button>
                        <Box textAlign="center" paddingTop={3}>
                            <Link color="wedje.500" as={RouterLink} to="/login">
                                Terug naar login
                            </Link>
                        </Box>
                    </Form>
                )}
            </Formik>
        </FullCenterForm>
    );
};

export default PasswordResetForm;
