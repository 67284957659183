import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Fade,
    Flex,
    Input,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react';
import { FaHandshake } from 'react-icons/fa';
import * as yup from 'yup';
import ApiHelper from '../../utils/ApiHelper';
import { RouterLink, User, Wedje, WedjeAnswer } from '../../types';
import { wedjeFromJSON } from '../../utils/WedjeHelper';
import { AuthProviderContext } from '../../AuthProvider';
import { WAGER_EXPLAINED } from '../../constants';

const WedjeAcceptForm: FunctionComponent = () => {
    const { shareCode } = useParams<any>();
    const { isAuthenticated, user } = useContext(AuthProviderContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [wedje, setWedje] = useState<Wedje | null>(null);
    const [opponent, setOpponent] = useState<Partial<User>>({
        id: 0,
        name: 'iemand',
    });
    const [messageTitle, setMessageTitle] = useState<string>('');
    const [messageText, setMessageText] = useState<string>('');
    const [showEMailInput, setShowEMailInput] = useState<boolean>(true);
    const [sendAnswer, setSendAnswer] = useState<Partial<WedjeAnswer>>({});
    const [wedjeError, setWedjeError] = useState<string>('');
    const history = useHistory();
    const toast = useToast();

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.getWedjeByShareCode(shareCode)
            .then((res) => {
                setIsLoading(false);
                if (res.status === 200 && res.json) {
                    const parsedWedje = wedjeFromJSON(res.json);
                    setWedje(parsedWedje);
                    if (res.json.user_id && res.json.user) {
                        const wedjeUser = res.json.user as Record<
                            string,
                            unknown
                        >;
                        setOpponent({
                            id: wedjeUser.id,
                            name: wedjeUser.name,
                        } as Partial<User>);
                        if (
                            isAuthenticated &&
                            user &&
                            wedjeUser.id === user?.id
                        ) {
                            setMessageTitle('Wedje leggen?');
                            setMessageText(
                                `Hey ${user?.name}, dit wedje heb je zelf gemaakt en kun je niet zelf invullen, ga naar je profiel om informatie over dit wedje te bekijken`
                            );
                        }
                    } else {
                        setOpponent({
                            id: 0,
                            name: String(res.json?.name) ?? 'iemand',
                        });
                    }

                    if (parsedWedje) {
                        const myAnswer = parsedWedje.answer.find(
                            (a) => a.name === '' && a.userId === 0 && !a.locked
                        );
                        if (!myAnswer) {
                            console.error(
                                'Could not determine what answer is up'
                            );
                            setMessageTitle('Wedje al aangegaan');
                            setMessageText(
                                'Helaas kun je dit wedje niet meer leggen omdat het al aangegaan is. Je kunt natuurlijk altijd zelf een nieuw wedje maken'
                            );
                        } else {
                            setSendAnswer(myAnswer);
                        }
                    }
                } else {
                    setMessageTitle('Wedje niet gevonden?');
                    setMessageText(
                        'Het wedje kon niet gevonden worden? Controleer de link, of kopieer / plak deze handmatig. Het kan ook zijn dat het wedje is verwijderd'
                    );
                }
                return res;
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
                setMessageTitle('Wedje niet gevonden?');
                setMessageText(
                    'Het wedje kon niet gevonden worden? Controleer de link, of kopieer / plak deze handmatig. Het kan ook zijn dat het wedje is verwijderd'
                );
            });
    }, [isAuthenticated, shareCode, user]);

    const showAnswer = (answer: WedjeAnswer) => {
        if (answer.provideLater === true) {
            return null;
        }

        if (answer.name === '' && answer.userId === 0) {
            return (
                <Text key={`answ1-${answer.id}`} paddingTop={1} color="white">
                    Jij denkt {answer.answer}
                </Text>
            );
        }

        return (
            <Text key={`answ2-${answer.id}`} paddingTop={1} color="white">
                {opponent && opponent.id === answer.userId
                    ? opponent.name
                    : answer.name}{' '}
                denkt {answer.answer}
            </Text>
        );
    };

    if (isLoading) {
        return (
            <Box
                minWidth="360px"
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
                bg="backgrounds.300"
                width="100%"
            >
                <Box paddingX={8} paddingTop={4} paddingBottom={6} minH={280}>
                    <Text fontSize="xl" color="wedje.300">
                        Laden
                    </Text>
                    <Text>Wedje wordt opgehaald</Text>
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="200px"
                    >
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            color="wedje.500"
                            size="xl"
                        />
                    </Flex>
                </Box>
            </Box>
        );
    }

    if (messageText !== '') {
        return (
            <Box
                minWidth="360px"
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
                bg="backgrounds.300"
                width="100%"
            >
                <Box paddingX={8} paddingTop={4} paddingBottom={6} minH={280}>
                    <Text fontSize="xl" color="wedje.300">
                        {messageTitle}
                    </Text>
                    <Text fontSize="md" paddingTop={4}>
                        {messageText}
                    </Text>
                </Box>
            </Box>
        );
    }

    const canPovideAnswer = !!wedje?.answer.find(
        (a) => a.provideLater === true
    );

    const handleUpdateCheckbox = (event: any) => {
        setShowEMailInput(event.target.checked);
    };
    const handleChangeEmail = (event: any) => {
        setSendAnswer({
            ...sendAnswer,
            email: event.target.value,
        });
    };
    const handleChangeName = (event: any) => {
        setSendAnswer({
            ...sendAnswer,
            name: event.target.value,
        });
    };
    const handleChangeAnswer = (event: any) => {
        setSendAnswer({
            ...sendAnswer,
            answer: event.target.value,
        });
    };

    const handleSaveWedje = () => {
        let validationSchema = yup.object().shape({
            id: yup.number().required('Wedje niet gevonden?!'),
            name: yup.string().required('Vul je naam in'),
            email: yup
                .string()
                .email('Vul een geldig e-mailadres in')
                .required('Vul je e-mailadres in'),
            answer: yup.string().required('Vul je voorspelling in'),
        });
        const omit = [];
        if (isAuthenticated && !user) {
            omit.push('name');
        }
        if (!showEMailInput) {
            omit.push('email');
        }
        if (!canPovideAnswer) {
            omit.push('answer');
        }
        validationSchema = validationSchema.omit(omit);
        validationSchema
            .validate(sendAnswer)
            .then(() => {
                setWedjeError('');
                // Inject userid at the latest possible time since the user can login at any time
                if (isAuthenticated && user) {
                    sendAnswer.userId = user.id;
                }
                setIsLoading(true);
                return ApiHelper.updateAnswer(
                    wedje!.id!,
                    sendAnswer.id!,
                    sendAnswer as WedjeAnswer
                );
            })
            .then((res) => {
                setIsLoading(false);
                if (res.status === 200 && res.json) {
                    history.push('/wedje-accepted');
                } else {
                    toast({
                        title: 'Er gaat iets mis',
                        description: String(res?.json?.message) || String(res),
                        status: 'error',
                    });
                }
                return res;
            })
            .catch((err) => {
                setWedjeError(String(err.errors));
            });
    };

    return (
        <Box
            minWidth="360px"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
            bg="backgrounds.300"
            width="100%"
        >
            <Box paddingX={8} paddingTop={4} paddingBottom={8} minH={280}>
                <Text fontSize="xl" color="wedje.300" paddingBottom={2}>
                    {wedje?.subject}
                </Text>
                <Text as="span" color="white">
                    {opponent.name} wil een wedje leggen met als inzet:{' '}
                    <Popover trigger="click">
                        <PopoverTrigger>
                            <Text color="white" as="u" cursor="pointer">
                                {wedje?.wager}
                            </Text>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>
                                {(wedje?.wagerType &&
                                    WAGER_EXPLAINED.get(wedje?.wagerType)
                                        ?.title) ??
                                    ''}
                            </PopoverHeader>
                            <PopoverBody>
                                {(wedje?.wagerType &&
                                    WAGER_EXPLAINED.get(wedje?.wagerType)
                                        ?.explained) ??
                                    ''}{' '}
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </Text>
                <Box paddingTop={4} paddingBottom={4}>
                    <Text color="wedje2.200" as="span">
                        {wedje?.answer.map((a) => showAnswer(a))}
                    </Text>
                </Box>
                <Divider />
                <Text
                    fontSize="xl"
                    paddingBottom={2}
                    paddingTop={3}
                    color="wedje.300"
                >
                    Wedje leggen?
                </Text>
                {!isAuthenticated && !user && (
                    <Box paddingTop={2}>
                        <Text fontSize="sm" color="wedje2.200">
                            Jouw naam
                        </Text>
                        <Input onChange={handleChangeName} />
                    </Box>
                )}
                {canPovideAnswer && (
                    <Box paddingTop={2}>
                        <Text fontSize="sm" color="wedje2.200">
                            Jouw voorspelling
                        </Text>
                        <Input onChange={handleChangeAnswer} />
                    </Box>
                )}
                <Checkbox
                    paddingTop={2}
                    onChange={handleUpdateCheckbox}
                    defaultIsChecked
                >
                    <Text fontSize="sm">
                        Stuur me een e-mail als het wedje afloopt
                    </Text>
                </Checkbox>
                {showEMailInput && !isAuthenticated && !user && (
                    <>
                        <Input
                            marginY={4}
                            onChange={handleChangeEmail}
                            placeholder="email@voorbeeld.nl"
                            value={sendAnswer.email || ''}
                        />
                        <Text fontSize="sm">
                            Je kunt ook{' '}
                            <Link
                                as={RouterLink}
                                color="wedje.500"
                                to="/register"
                            >
                                registeren
                            </Link>{' '}
                            of{' '}
                            <Link as={RouterLink} color="wedje.500" to="/login">
                                inloggen
                            </Link>{' '}
                            om al je wedjes in een overzicht te zien
                        </Text>
                        <Text fontSize="sm" color="wedje2.400" paddingTop={2}>
                            Je e-mailadres wordt voor niets anders gebruikt dan
                            het sturen van een &eacute;&eacute;nmalige
                            herinnering aan je weddenschap
                        </Text>
                    </>
                )}
            </Box>
            <Fade in={wedjeError !== ''}>
                <Box width="100%" position="relative">
                    <Box
                        top="-24px"
                        height="24px"
                        bg="wedje.300"
                        color="wedje2.700"
                        width="100%"
                        overflow="hidden"
                        textAlign="center"
                        marginBottom="2px"
                        position="absolute"
                    >
                        {wedjeError}
                    </Box>
                </Box>
            </Fade>
            <Button
                isFullWidth
                color="wedje.500"
                rightIcon={<FaHandshake />}
                onClick={handleSaveWedje}
            >
                Ga wedje aan
            </Button>
        </Box>
    );
};

export default WedjeAcceptForm;
