import * as React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import Routes from './Routes';
import 'moment/locale/nl';
import AuthProvider from './AuthProvider';

// Theme generated using: https://smart-swatch.netlify.app/
const customTheme = extendTheme({
    config: {
        initialColorMode: 'dark',
    },
    colors: {
        backgrounds: {
            300: '#212938',
            500: '#1A202C',
            700: '#171923',
        },
        wedje: {
            50: '#ffffff',
            100: '#fdffdb',
            200: '#f5ff7d',
            300: '#f0ff4b',
            400: '#ecff1a',
            500: '#d3e600',
            600: '#a4b300',
            700: '#758000',
            800: '#454d00',
            900: '#171b00',
        },
        wedje2: {
            50: '#ebf1ff',
            100: '#cdd5e7',
            200: '#aebad1',
            300: '#8e9ebd',
            400: '#6f82a9',
            500: '#566990',
            600: '#425171',
            700: '#2e3a51',
            800: '#1a2333',
            900: '#020d18',
        },
        // For toasts
        green: {
            50: '#ffffff',
            100: '#fdffdb',
            200: '#f5ff7d',
            300: '#d3e600', // Toast success color
            400: '#d3e600',
            500: '#d3e600',
            600: '#a4b300',
            700: '#758000',
            800: '#454d00',
            900: '#171b00',
        },
    },
    styles: {
        global: {
            // styles for the `body`
            body: {
                bg: 'backgrounds.500',
                color: 'white',
                width: '100%',
            },
            '.DayPicker-Months': {
                margin: 0,
            },
        },
    },
});

const App: FunctionComponent = () => (
    <ChakraProvider theme={customTheme}>
        <AuthProvider>
            <Routes />
        </AuthProvider>
    </ChakraProvider>
);

export default App;
