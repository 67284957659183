import React, { FunctionComponent, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { AuthProviderContext } from './AuthProvider';

const PrivateRoute: FunctionComponent<RouteProps> = ({ children, ...rest }) => {
    const { isLoading, isAuthenticated } = useContext(AuthProviderContext);
    if (isLoading) {
        return <div />;
    }
    return (
        <Route
            {...rest}
            render={() =>
                isAuthenticated ? children : <Redirect to="/login" />
            }
        />
    );
};

export default PrivateRoute;
