import { ApiResult, Wedje, WedjeAnswer } from '../types';

export default class ApiHelper {
    public static isAuthenticated = false;

    public static token = '';

    // public static apiUrl =
    //     process.env.NODE_ENV === 'production'
    //         ? 'https://wedje.nu/api'
    //         : 'http://localhost:8080/api';

    public static apiUrl = 'https://wedje.nu/api';

    public static login = (
        email: string,
        password: string
    ): Promise<ApiResult> =>
        ApiHelper.post('/login', {
            email,
            password,
        });

    public static register = (
        name: string,
        email: string,
        password: string
    ): Promise<ApiResult> =>
        ApiHelper.post('/register', {
            name,
            email,
            password,
        });

    public static forgotPassword = (email: string): Promise<ApiResult> =>
        ApiHelper.post('/forgot-password', {
            email,
        });

    public static checkResetPasswordToken = (
        token: string
    ): Promise<ApiResult> =>
        ApiHelper.post('/check-token', {
            token,
        });

    public static resetPassword = (
        token: string,
        email: string,
        password: string
    ): Promise<ApiResult> =>
        ApiHelper.post('/reset-password', {
            token,
            email,
            password,
        });

    public static updateProfile = (
        name: string,
        email: string,
        password: string
    ): Promise<ApiResult> =>
        ApiHelper.post('/profile', {
            name,
            email,
            password,
        });

    public static getMe = (): Promise<ApiResult> => ApiHelper.get('/me');

    public static getWedjeByShareCode = (shareCode: string) =>
        ApiHelper.get(`/wedje/share_code/${shareCode}`);

    public static addOrUpdateWedje = (wedje: Wedje) =>
        wedje.id
            ? ApiHelper.updateWedje(wedje.id!, wedje)
            : ApiHelper.addWedje(wedje);

    public static addWedje = (newWedje: Wedje) =>
        ApiHelper.put('/wedje', newWedje);

    public static updateWedje = (id: number, updatedWedje: Wedje) =>
        ApiHelper.post(`/wedje/${id}`, updatedWedje);

    public static addOrUpdateAnswer = (wedjeId: number, answer: WedjeAnswer) =>
        answer.id
            ? ApiHelper.updateAnswer(wedjeId, answer.id, answer)
            : ApiHelper.addAnswer(wedjeId, answer);

    public static addAnswer = (wedjeId: number, newAnswer: WedjeAnswer) =>
        ApiHelper.put(`/wedje/${wedjeId}/answer`, newAnswer);

    public static updateAnswer = (
        wedjeId: number,
        id: number,
        updatedAnswer: WedjeAnswer
    ) => ApiHelper.post(`/wedje/${wedjeId}/answer/${id}`, updatedAnswer);

    public static getWedjes = () => ApiHelper.get(`/wedje`);

    public static setToken = (token: string) => {
        ApiHelper.token = token;
        ApiHelper.isAuthenticated = true;
    };

    private static post = (
        url: string,
        data: Record<string, any>
    ): Promise<ApiResult> =>
        fetch(`${ApiHelper.apiUrl}${url}`, {
            method: 'post',
            body: JSON.stringify(data),
            headers: ApiHelper.headers(),
        }).then(async (response) => {
            const json = await response.json();
            return {
                status: response.status,
                json,
            } as ApiResult;
        });

    private static put = (
        url: string,
        data: Record<string, any>
    ): Promise<ApiResult> =>
        fetch(`${ApiHelper.apiUrl}${url}`, {
            method: 'put',
            body: JSON.stringify(data),
            headers: ApiHelper.headers(),
        }).then(async (response) => {
            const json = await response.json();
            return {
                status: response.status,
                json,
            } as ApiResult;
        });

    private static get = (url: string): Promise<ApiResult> =>
        fetch(`${ApiHelper.apiUrl}${url}`, {
            method: 'get',
            headers: ApiHelper.headers(),
        }).then(async (response) => {
            const json = await response.json();
            return {
                status: response.status,
                json,
            } as ApiResult;
        });

    private static headers = (): HeadersInit => {
        if (ApiHelper.token.length > 0) {
            return {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ApiHelper.token}`,
            };
        }
        return {
            'Content-Type': 'application/json',
        };
    };
}
