import React, { FunctionComponent } from 'react';
import DayPicker from 'react-day-picker';
import './datepicker.css';
import { DayPickerProps } from 'react-day-picker/types/Props';
import MomentLocaleUtils from 'react-day-picker/moment';

interface Props extends DayPickerProps {
    onChange: (newDate: Date) => void;
    value: Date;
}

const DatePicker: FunctionComponent<Props> = ({
    onChange,
    value,
    ...props
}) => (
    <DayPicker
        localeUtils={MomentLocaleUtils}
        locale="nl"
        showOutsideDays
        onDayClick={onChange}
        selectedDays={value}
        disabledDays={[
            {
                before: new Date(),
            },
        ]}
        month={value}
        {...props}
    />
);

export default DatePicker;
