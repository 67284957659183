import React, { FunctionComponent } from 'react';
import {
    Box,
    Container,
    Heading,
    Stack,
    Text,
    Image,
    SimpleGrid,
} from '@chakra-ui/react';

const AboutPage: FunctionComponent = () => (
    <Box p={4}>
        <Stack spacing={4} as={Container} maxW="3xl" textAlign="center">
            <Heading fontSize="3xl">Over wedje.nu</Heading>
            <Text color="wedje.200" fontSize="xl">
                Op wedje.nu kun je weddenschappen registeren tussen jou en je
                vrienden, collega`s, familie, noem maar op. Wedje.nu houdt voor
                jou bij welke weddenschappen wanneer aflopen en wat de inzet is.
                Je krijgt een mailtje aan het eind van de weddenschap om je te
                herinneren dat de weddenschap afloopt.
            </Text>
            <Heading fontSize="2xl" paddingTop={4}>
                Registreer je nu
            </Heading>
            <SimpleGrid columns={{ sm: 1, md: 2 }} paddingY={4} maxWidth={900}>
                <Box>
                    <Text color="wedje2.300" fontSize="lg" textAlign="left">
                        Een account aanmmaken is niet verplicht, maar wel
                        handig! Je hoeft dan niet steeds je naam en e-mailadres
                        in te vullen. Ook kun je na inloggen een mooi overzicht
                        bekijken van alle weddenschappen waar je aan mee doet.
                    </Text>{' '}
                </Box>
                <Box>
                    <Image
                        src="about_profile.png"
                        border="1px solid #566990"
                        borderRadius={8}
                        width="100"
                    />
                </Box>
            </SimpleGrid>
            <Heading fontSize="2xl" paddingTop={4}>
                Geen kleine lettertjes
            </Heading>
            <Text color="wedje2.300" fontSize="lg" textAlign="left">
                <Text as="span" color="wedje.300">
                    Wedje.nu{' '}
                </Text>
                is een hobbyproject omdat ik simpel en snel in de kroeg
                weddenschappen wilde bijhouden met mijn vrienden. Er zit geen
                bedrijf achter, geen kleine lettertjes, geen winstoogmerk.
            </Text>
        </Stack>
    </Box>
);

export default AboutPage;
