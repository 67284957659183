import moment from 'moment';
import { Wedje, WedjeAnswer } from '../types';
import { WagerType, WedjeType } from '../constants';

const wedjeFormStateToString = (wedje: Wedje, step: number): string => {
    try {
        return JSON.stringify({
            wedje,
            step,
        });
    } catch (e) {
        console.warn('Failed form to string', e);
    }
    return '';
};

const stringToWedjeFormState = (
    inStr: string
): { wedje: Wedje; step: number } | null => {
    try {
        const res = JSON.parse(inStr);
        if (res.wedje && res.step) {
            return {
                wedje: res.wedje,
                step: res.step,
            };
        }
    } catch (e) {
        console.warn('Failed string to form', e);
    }
    return null;
};

const wedjeFromJSON = (json: Record<string, unknown>): Wedje | null => {
    try {
        return {
            id: Number(json.id),
            type: json.type as WedjeType,
            subject: String(json.subject),
            answer: (json.answer as Record<string, unknown>[]).map((a) =>
                wedjeAnswerFromJSON(a)
            ),
            wager: String(json.wager),
            wagerType: json.wager_type as WagerType,
            end: json.end,
            ownerUserId: Number(json.user_id),
            ownerName: String(json.name),
            ownerEmail: String(json.email),
            updateByEmail: !!json.update_by_email,
            shareCode: String(json.share_code),
            locked: !!json.locked,
            createdAt: moment(String(json.created_at)).toDate(),
            updatedAt: moment(String(json.updated_at)).toDate(),
        } as Wedje;
    } catch (err) {
        console.error('Failed json to wedje', err);
        return null;
    }
};

const wedjeAnswerFromJSON = (
    json: Record<string, unknown>
): WedjeAnswer | null => {
    try {
        return {
            id: Number(json.id),
            userId: Number(json.user_id),
            answer: String(json.answer),
            provideLater: !!json.can_provide_later,
            name: String(json.name),
            email: String(json.email),
            updateByEmail: !!json.update_by_email,
            locked: !!json.locked,
            createdAt: moment(String(json.created_at)).toDate(),
            updatedAt: moment(String(json.updated_at)).toDate(),
        } as WedjeAnswer;
    } catch (err) {
        console.error('Failed json to wedje answer', err);
        return null;
    }
};

export { wedjeFormStateToString, stringToWedjeFormState, wedjeFromJSON };
