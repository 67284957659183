import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    Box,
    Flex,
    Avatar,
    HStack,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    Stack,
    Text,
    Link,
    Image,
} from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
import { useHistory } from 'react-router';
import NavLink from './NavLink';
import { RouterLink } from '../../types';
import { AuthProviderContext } from '../../AuthProvider';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { LOCAL_STORAGE_WEDJE_KEY } from '../../constants';
import { stringToWedjeFormState } from '../../utils/WedjeHelper';

const NavBar: FunctionComponent = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isAuthenticated, user, logout } = useContext(AuthProviderContext);
    const history = useHistory();

    const [localWedje, setLocalWedje] = useLocalStorage(
        LOCAL_STORAGE_WEDJE_KEY,
        ''
    );
    const [isBuildingWedje, setIsBuildingWedje] = useState<boolean>(false);

    useEffect(() => {
        if (localWedje.length > 0) {
            const wedjeState = stringToWedjeFormState(localWedje);
            if (wedjeState && wedjeState.step > 0) {
                setIsBuildingWedje(true);
            }
        }
    }, [localWedje]);

    const handleClearWedje = () => {
        setLocalWedje('');
        setIsBuildingWedje(false);
        history.push('/');
    };

    const links = (
        <>
            <NavLink key="home" to="/">
                Wedje maken
            </NavLink>
            <NavLink key="about" to="/about">
                Meer informatie
            </NavLink>
        </>
    );

    const handleLogout = () => {
        logout();
    };

    return (
        <>
            <Box bg="backgrounds.300" px={4} boxShadow="md">
                <Flex h={16} alignItems="center" justifyContent="space-between">
                    <IconButton
                        size="md"
                        icon={isOpen ? <GrClose /> : <GiHamburgerMenu />}
                        aria-label="Open Menu"
                        display={{ md: !isOpen ? 'none' : 'inherit' }}
                        onClick={isOpen ? onClose : onOpen}
                        style={{
                            paddingLeft: '12px',
                        }}
                    />
                    <HStack spacing={8} alignItems="center">
                        <Image src="/logo.png" />
                        <HStack
                            as="nav"
                            spacing={4}
                            display={{ base: 'none', md: 'flex' }}
                        >
                            {links}
                        </HStack>
                    </HStack>
                    <Flex alignItems="center">
                        {isAuthenticated ? (
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    rounded="full"
                                    variant="link"
                                    cursor="pointer"
                                >
                                    <Avatar
                                        bg="wedje.500"
                                        name={user?.name}
                                        size="sm"
                                    />
                                </MenuButton>
                                <MenuList>
                                    <MenuItem as={RouterLink} to="/overview">
                                        Mijn wedjes
                                    </MenuItem>
                                    <MenuItem as={RouterLink} to="/profile">
                                        Profiel
                                    </MenuItem>
                                    <MenuDivider />
                                    <MenuItem onClick={handleLogout}>
                                        Uitloggen
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        ) : (
                            <Stack
                                flex={{ base: 1, md: 0 }}
                                justify="flex-end"
                                direction="row"
                                spacing={6}
                            >
                                <Button
                                    fontSize="sm"
                                    fontWeight={400}
                                    variant="link"
                                    as={RouterLink}
                                    to="/login"
                                >
                                    Inloggen
                                </Button>
                                <Button
                                    display={{
                                        base: 'none',
                                        md: 'inline-flex',
                                    }}
                                    fontSize="sm"
                                    fontWeight={600}
                                    color="white"
                                    as={RouterLink}
                                    to="/register"
                                    _hover={{
                                        bg: 'wedje.500',
                                    }}
                                >
                                    Registeren
                                </Button>
                            </Stack>
                        )}
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4}>
                        <Stack as="nav" spacing={4}>
                            {links}
                        </Stack>
                    </Box>
                ) : null}
            </Box>

            {isBuildingWedje && (
                <Box bg="wedje2.700" minHeight="28px" paddingX={2} paddingY={1}>
                    <Text color="wedje2.100" fontSize="sm">
                        Je bent nog bezig met het maken van een wedje.{' '}
                        <Link as={RouterLink} to="/" color="wedje.500">
                            Verder gaan
                        </Link>{' '}
                        of{' '}
                        <Link onClick={handleClearWedje} color="wedje.500">
                            wedje vergeten
                        </Link>
                    </Text>
                </Box>
            )}
        </>
    );
};

export default NavBar;
