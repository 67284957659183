import React, { FunctionComponent } from 'react';
import WedjeAcceptForm from './WedjeAcceptForm';
import TwoColPage from '../global/TwoColPage';
import IntroColumn from '../static/IntroColumn';

const WedjeAccept: FunctionComponent = () => (
    <TwoColPage leftColumn={<WedjeAcceptForm />}>
        <IntroColumn />
    </TwoColPage>
);

export default WedjeAccept;
