import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router';
import { Box, Container, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { RouterLink } from '../../types';

const NotFoundPage: FunctionComponent = () => {
    const location = useLocation();

    console.log('failed', location);
    return (
        <Box p={4}>
            <Stack spacing={4} as={Container} maxW="3xl" textAlign="center">
                <Heading fontSize="3xl">404: niet gevonden</Heading>
                <Text color="wedje.200" fontSize="xl">
                    De pagina {location.pathname} kon niet gevonden worden.{' '}
                    <br />
                    <Link as={RouterLink} to="/" color="wedje.50">
                        Klik hier om terug te gaan naar de homepage
                    </Link>
                </Text>
            </Stack>
        </Box>
    );
};

export default NotFoundPage;
