import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import LoginForm from './components/user/LoginForm';
import PrivateRoute from './PrivateRoute';
import RegistrationForm from './components/user/RegistrationForm';
import ForgotPasswordForm from './components/user/ForgotPasswordForm';
import PasswordResetForm from './components/user/PasswordResetForm';
import NavBar from './components/global/NavBar';
import HomePage from './components/static/HomePage';
import AboutPage from './components/static/AboutPage';
import WedjeAccept from './components/wedje/WedjeAccept';
import NotFoundPage from './components/static/NotFoundPage';
import WedjeGiphy from './components/wedje/WedjeGiphy';
import WedjeOverview from './components/wedje/WedjeOverview';
import WedjeShare from './components/wedje/WedjeShare';
import ProfilePage from './components/user/ProfilePage';

const Routes: FunctionComponent = () => (
    <Router>
        <NavBar />
        <Box maxWidth={900} marginX="auto" marginBottom={4}>
            <Switch>
                <Route path="/login">
                    <LoginForm />
                </Route>
                <Route path="/register">
                    <RegistrationForm />
                </Route>
                <Route path="/forgot-password">
                    <ForgotPasswordForm />
                </Route>
                <Route path="/reset-password">
                    <PasswordResetForm />
                </Route>
                <Route path="/about">
                    <AboutPage />
                </Route>
                <Route path="/w/:shareCode">
                    <WedjeAccept />
                </Route>
                <Route path="/share/:shareCode">
                    <WedjeShare />
                </Route>
                <Route path="/wedje-accepted">
                    <WedjeGiphy cause="accepted" />
                </Route>
                <Route exact path="/">
                    <HomePage />
                </Route>
                <PrivateRoute path="/profile">
                    <ProfilePage />
                </PrivateRoute>
                <PrivateRoute path="/overview">
                    <WedjeOverview />
                </PrivateRoute>
                <Route path="*">
                    <NotFoundPage />
                </Route>
            </Switch>
        </Box>
    </Router>
);
export default Routes;
