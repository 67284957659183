import React, { FunctionComponent } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Link,
    useToast,
} from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { Field, FieldProps, Form, Formik } from 'formik';
import ApiHelper from '../../utils/ApiHelper';
import FullCenterForm from '../global/FullCenterForm';
import { RouterLink } from '../../types';

type FormValues = {
    email: string;
};

const initialValues: FormValues = {
    email: '',
};

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Vul een geldig e-mailadres in')
        .required('Vul je e-mailadres in'),
});

const ForgotPasswordForm: FunctionComponent = () => {
    const toast = useToast();

    const submitHandler = (values: FormValues) =>
        ApiHelper.forgotPassword(values.email)
            .then((res) => {
                if (res && res) {
                    switch (res.status) {
                        case 200:
                            toast({
                                title: String(
                                    res.json?.message || 'Wachtwoord verzonden'
                                ),
                                status: 'success',
                            });
                            break;
                        case 500:
                            toast({
                                title: 'Er gaat iets mis',
                                description: String(res.json?.message),
                                status: 'error',
                            });
                            break;
                        default:
                            toast({
                                title: 'Er gaat iets mis',
                                description: String(res.json?.message),
                                status: 'warning',
                            });
                            break;
                    }
                }
                return res;
            })
            .catch((error: string) => {
                toast({
                    title: 'Er gaat iets mis',
                    description: error.toString(),
                    status: 'error',
                });
            });

    if (ApiHelper.isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <FullCenterForm
            title="Wachtwoord vergeten"
            subtitle="Vul de velden hieronder in en we mailen je een wachtwoord reset link"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={submitHandler}
                validationSchema={validationSchema}
            >
                {(props) => (
                    <Form>
                        <Field name="email">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="email">
                                        E-mailadres
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="email"
                                        placeholder="test@test.com"
                                        size="lg"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Button
                            isLoading={props.isSubmitting}
                            disabled={!(props.isValid && props.dirty)}
                            type="submit"
                            width="full"
                            mt={4}
                        >
                            {props.isSubmitting ? (
                                <CircularProgress
                                    isIndeterminate
                                    size="24px"
                                    color="wedje.500"
                                />
                            ) : (
                                'Verzenden'
                            )}
                        </Button>
                        <Box textAlign="center" paddingTop={3}>
                            <Link color="wedje.500" as={RouterLink} to="/login">
                                Terug naar login
                            </Link>
                        </Box>

                        <Box textAlign="center" paddingTop={5}>
                            <Link
                                color="wedje.500"
                                as={RouterLink}
                                to="/register"
                            >
                                Geen account? Klik hier om te registreren
                            </Link>
                        </Box>
                    </Form>
                )}
            </Formik>
        </FullCenterForm>
    );
};

export default ForgotPasswordForm;
