import React, { FunctionComponent } from 'react';
import { Box, Container, Heading, Image, Stack } from '@chakra-ui/react';

type Props = {
    cause: string;
};

const WedjeGiphy: FunctionComponent<Props> = ({ cause }) => {
    let giphyList: string[] = [];
    let title = '';
    switch (cause) {
        case 'accepted': {
            title = 'Wedje gelegd';
            giphyList = [
                'https://media.giphy.com/media/XuBJvrKHutnkQ/giphy.gif',
                'https://media.giphy.com/media/3o6UB8j8iokF37kR20/giphy.gif',
                'https://media.giphy.com/media/l46C9OlKY1aSddB7i/giphy.gif',
                'https://media.giphy.com/media/I3EsiEPZWgpqg/giphy.gif',
                'https://media.giphy.com/media/8YyZVHQ7VgEftPbdaC/giphy.gif',
                'https://media.giphy.com/media/YUHWYvPAd3XUmD3IUO/giphy.gif',
            ];
            break;
        }
        default:
            giphyList = [
                'https://media.giphy.com/media/H7wajFPnZGdRWaQeu0/giphy.gif',
            ];
            break;
    }

    giphyList = giphyList.sort(() => Math.random() - 0.5);
    const giphy = giphyList.pop();

    return (
        <div>
            <Stack
                spacing={4}
                as={Container}
                maxW="3xl"
                textAlign="center"
                marginTop={8}
            >
                <Heading fontSize="3xl">{title}</Heading>
            </Stack>
            <Box
                marginTop={8}
                p={2}
                width="100%"
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
                bg="backgrounds.300"
            >
                <Image htmlWidth="100%" alt={cause} src={giphy} />
            </Box>
        </div>
    );
};

export default WedjeGiphy;
