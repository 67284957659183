import React, { FunctionComponent } from 'react';
import { Link } from '@chakra-ui/react';
import { RouterLink } from '../../types';

interface Props {
    to: string;
}

const NavLink: FunctionComponent<Props> = ({ children, to }) => (
    <Link
        px={2}
        py={1}
        rounded="md"
        _hover={{
            textDecoration: 'none',
            bg: 'backgrounds.500',
        }}
        as={RouterLink}
        to={to}
    >
        {children}
    </Link>
);

export default NavLink;
