import React, { FunctionComponent, useContext } from 'react';
import { Box, Checkbox, SimpleGrid, Input, Text } from '@chakra-ui/react';
import { Wedje, WedjeAnswer } from '../../types';
import { AuthProviderContext } from '../../AuthProvider';

interface Props {
    wedje: Wedje;
    onChange: (newWedje: Wedje) => void;
}

const WedjeFormAnswer: FunctionComponent<Props> = ({ wedje, onChange }) => {
    const { isAuthenticated, user } = useContext(AuthProviderContext);

    const answer: WedjeAnswer[] = [];
    // Own answer, fill in all the things
    answer[0] = wedje.answer[0] ?? { answer: '' };
    answer[0].email = wedje.ownerEmail || '';
    answer[0].name = wedje.ownerName || '';
    answer[0].userId = isAuthenticated && user ? user.id : 0;

    // Others answer, don't fill in
    answer[1] = wedje.answer[1] ?? { answer: '' };

    const handleChangeAnswer0 = (event: any) => {
        answer[0].answer = event.target.value;
        onChange({
            ...wedje,
            answer,
        });
    };
    const handleChangeAnswer1 = (event: any) => {
        answer[1].answer = event.target.value;
        onChange({
            ...wedje,
            answer,
        });
    };

    const handleAnswer1Block = (event: any) => {
        answer[1].provideLater = event.target.checked;
        onChange({
            ...wedje,
            answer,
        });
    };

    return (
        <>
            <Text fontSize="xl" color="wedje.300">
                Voorspellingen
            </Text>
            <Text fontSize="sm" color="wedje2.300" paddingTop={4}>
                Ik denk{' '}
                <Text as="span" color="wedje2.500" fontSize="sm">
                    *
                </Text>
            </Text>
            <Input
                marginY={2}
                onChange={handleChangeAnswer0}
                placeholder="Mijn voorspelling"
                value={answer[0].answer}
            />
            <SimpleGrid paddingTop={2}>
                <Box display="flex">
                    <Box flex={1}>
                        <Text fontSize="sm" color="wedje2.300">
                            De ander voorspelt
                        </Text>
                    </Box>
                    <Box display="flex" flex={1} paddingLeft={8}>
                        <Checkbox
                            spacing="1rem"
                            onChange={handleAnswer1Block}
                            defaultIsChecked={answer[1].provideLater}
                        >
                            <Text fontSize="sm" color="wedje2.300">
                                De ander vult zelf in
                            </Text>
                        </Checkbox>
                    </Box>
                </Box>
            </SimpleGrid>

            <Input
                marginY={2}
                onChange={handleChangeAnswer1}
                placeholder={
                    answer[1].provideLater
                        ? 'Vult zelf in'
                        : 'Voorspelling van de ander'
                }
                value={answer[1].provideLater ? '' : answer[1].answer}
                disabled={answer[1].provideLater}
            />
        </>
    );
};

export default WedjeFormAnswer;
