import React, { FunctionComponent, useState } from 'react';
import { Text, Stack, List, ListItem, ListIcon, Link } from '@chakra-ui/react';
import { GiSoccerBall } from 'react-icons/gi';
import moment from 'moment';
import WedjeForm from '../wedje/WedjeForm';
import { Wedje } from '../../types';
import TwoColPage from '../global/TwoColPage';
import IntroColumn from './IntroColumn';

const HomePage: FunctionComponent = () => {
    const [quickBet, setQuickBet] = useState<Partial<Wedje> | null>(null);
    const handleQuickBet = (newQuickBet: Partial<Wedje>) => () => {
        setQuickBet(newQuickBet);
    };

    return (
        <>
            <TwoColPage leftColumn={<WedjeForm quickWedje={quickBet} />}>
                <IntroColumn />
            </TwoColPage>
            <Stack
                spacing={0}
                width="100%"
                alignItems="left"
                padding={4}
                display="none"
            >
                <Text fontSize="xl" color="wedje2.200" paddingTop={4}>
                    Snelle wedjes
                </Text>
                <List spacing={3}>
                    <ListItem>
                        <ListIcon as={GiSoccerBall} color="wedje.200" />
                        <Link
                            fontSize="sm"
                            color="wedje.200"
                            onClick={handleQuickBet({
                                subject:
                                    'Wie wordt kampioen van de eredivisie in 2021',
                                answer: [{ answer: 'PSV' }, { answer: 'Ajax' }],
                                end: moment()
                                    .add(1, 'year')
                                    .month(6)
                                    .date(1)
                                    .unix(),
                            } as Wedje)}
                        >
                            Wie wordt kampioen van de eredivisie in 2021
                        </Link>
                    </ListItem>
                </List>
            </Stack>
        </>
    );
};

export default HomePage;
