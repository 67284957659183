import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import * as yup from 'yup';
import {
    Button,
    CircularProgress,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Skeleton,
    Text,
    useToast,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import ApiHelper from '../../utils/ApiHelper';
import FullCenterForm from '../global/FullCenterForm';
import { AuthProviderContext } from '../../AuthProvider';

type FormValues = {
    name: string;
    email: string;
    password: string;
    passwordConfirm: string;
};

const validationSchema = yup.object().shape({
    name: yup.string().required('Vul je naam in'),
    email: yup
        .string()
        .email('Vul een geldig e-mailadres in')
        .required('Vul je e-mailadres in'),
    password: yup
        .string()
        .min(8, 'Wachtwoord is te kort, moet minimaal 8 karakters zijn'),
    passwordConfirm: yup
        .string()
        .oneOf(
            [yup.ref('password'), null],
            'Wachtwoorden moeten hetzelfde zijn'
        ),
});

const ProfilePage: FunctionComponent = () => {
    const { user } = useContext(AuthProviderContext);
    const [initialValues, setInitialValues] = useState<FormValues | null>(null);
    useEffect(() => {
        if (user) {
            setInitialValues({
                name: user.name,
                email: user.email,
                password: '',
                passwordConfirm: '',
            } as FormValues);
        }
    }, [setInitialValues, user]);
    const toast = useToast();

    const submitHandler = (values: FormValues) =>
        ApiHelper.updateProfile(values.name, values.email, values.password)
            .then((res) => {
                if (res.status !== 201) {
                    let error = '';
                    if (res.json) {
                        Object.values(res.json).forEach((value) => {
                            error = `${error} ${value}`;
                        });
                    }
                    toast({
                        title: `Er gaat iets mis`,
                        description: error,
                    });
                } else {
                    toast({
                        title: `Je profiel is bijgewerkt`,
                        status: 'success',
                    });
                }
                return res;
            })
            .catch((error: string) => {
                toast({
                    title: 'Er gaat iets mis',
                    description: error.toString(),
                    status: 'error',
                });
            });

    if (!initialValues) {
        return (
            <FullCenterForm
                title="Profiel"
                subtitle="Gebruik de velden hieronder om je profiel bij te werken"
            >
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </FullCenterForm>
        );
    }

    return (
        <FullCenterForm
            title="Profiel"
            subtitle="Gebruik de velden hieronder om je profiel bij te werken"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={submitHandler}
                validationSchema={validationSchema}
            >
                {(props) => (
                    <Form>
                        <Field name="name">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="name">Naam</FormLabel>
                                    <Input
                                        {...field}
                                        id="name"
                                        placeholder="Naam"
                                        size="lg"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="email">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="email">
                                        E-mailadres
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="email"
                                        placeholder="test@test.com"
                                        size="lg"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="password">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="password">
                                        Wachtwoord{' '}
                                        <Text
                                            as="span"
                                            color="wedje2.300"
                                            fontSize="sm"
                                        >
                                            (Laat leeg voor geen wijziging)
                                        </Text>
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="password"
                                        placeholder="*********"
                                        size="lg"
                                        type="password"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="passwordConfirm">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                >
                                    <FormLabel htmlFor="passwordConfirm">
                                        Wachtwoord{' '}
                                        <Text
                                            as="span"
                                            color="wedje2.300"
                                            fontSize="sm"
                                        >
                                            (Ter controle)
                                        </Text>
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="passwordConfirm"
                                        placeholder="*********"
                                        size="lg"
                                        type="password"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Button
                            isLoading={props.isSubmitting}
                            type="submit"
                            width="full"
                            mt={4}
                        >
                            {props.isSubmitting ? (
                                <CircularProgress
                                    isIndeterminate
                                    size="24px"
                                    color="wedje.500"
                                />
                            ) : (
                                'Bijwerken'
                            )}
                        </Button>
                    </Form>
                )}
            </Formik>
        </FullCenterForm>
    );
};

export default ProfilePage;
