import React, { FunctionComponent } from 'react';
import { Image, Stack, Text } from '@chakra-ui/react';

const IntroColumn: FunctionComponent = () => (
    <Stack spacing={0} width="100%" alignItems="center" paddingX={4}>
        <Image src="/logo_big.png" maxWidth={240} />
        <Text fontSize="sm" color="wedje2.400" paddingTop={4}>
            Op
            <Text color="wedje.400" as="span">
                {' '}
                wedje.nu{' '}
            </Text>
            kun je snel een wedje registeren tussen jou en je vrienden. Vul de
            velden in, deel het wedje en krijg een seintje als het wedje
            afloopt. Vergeet nooit meer hoe het ook alweer zat en wat de inzet
            was.
        </Text>
    </Stack>
);

export default IntroColumn;
