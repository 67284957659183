import React, { FunctionComponent } from 'react';
import { Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { Wedje } from '../../types';
import { WAGER_EXPLAINED, WagerType } from '../../constants';

interface Props {
    wedje: Wedje;
    onChange: (newWedje: Wedje) => void;
}

const WedjeFormWager: FunctionComponent<Props> = ({ wedje, onChange }) => {
    const handleChange = (event: any) => {
        onChange({
            ...wedje,
            wager: event.target.value,
        });
    };

    const handleWagerMethod = (wagerType: WagerType) => {
        onChange({
            ...wedje,
            wagerType,
        });
    };

    return (
        <>
            <Text fontSize="xl" color="wedje.300">
                Inzet
            </Text>
            <Text fontSize="sm" color="wedje2.300" paddingTop={4}>
                Wat is de inzet?{' '}
                <Text as="span" color="wedje2.500" fontSize="sm">
                    *
                </Text>
            </Text>
            <Input
                marginY={2}
                onChange={handleChange}
                placeholder="Kratje 24"
                value={wedje.wager}
            />
            <Text fontSize="sm" color="wedje2.300" paddingTop={4}>
                Hoe wordt de inzet verdeeld?{' '}
                <Text as="span" color="wedje2.500" fontSize="sm">
                    *
                </Text>
            </Text>
            <RadioGroup
                onChange={handleWagerMethod}
                defaultValue={wedje.wagerType}
                marginTop={2}
            >
                <Stack spacing={4} direction="column">
                    <Radio value={WagerType.pot} colorScheme="wedje">
                        <Text color="wedje2.50">
                            {WAGER_EXPLAINED.get(WagerType.pot)!.title}{' '}
                            <Text color="wedje2.300" as="span" fontSize="sm">
                                {WAGER_EXPLAINED.get(WagerType.pot)!.explained}
                            </Text>
                        </Text>
                    </Radio>
                    <Radio value={WagerType.single} colorScheme="wedje">
                        <Text color="wedje2.50">
                            {WAGER_EXPLAINED.get(WagerType.single)!.title}{' '}
                            <Text color="wedje2.300" as="span" fontSize="sm">
                                {
                                    WAGER_EXPLAINED.get(WagerType.single)!
                                        .explained
                                }
                            </Text>
                        </Text>
                    </Radio>
                    <Radio value={WagerType.oneSided} colorScheme="wedje">
                        <Text color="wedje2.50">
                            {WAGER_EXPLAINED.get(WagerType.oneSided)!.title}{' '}
                            <Text color="wedje2.300" as="span" fontSize="sm">
                                {
                                    WAGER_EXPLAINED.get(WagerType.oneSided)!
                                        .explained
                                }
                            </Text>
                        </Text>
                    </Radio>
                </Stack>
            </RadioGroup>
        </>
    );
};

export default WedjeFormWager;
