import React, { ReactNode } from 'react';
import { Text } from '@chakra-ui/react';

export enum WedjeType {
    oneOnOne = 'one-on-one',
    freeForAll = 'free-for-all',
}

export enum WagerType {
    pot = 'pot',
    single = 'single',
    oneSided = 'one-sided',
}

interface WagerExplaination {
    title: string;
    explained: ReactNode;
}

export const WAGER_EXPLAINED = new Map<WagerType, WagerExplaination>();
WAGER_EXPLAINED.set(WagerType.pot, {
    title: 'Pot',
    explained: (
        <>
            Alle verliezers geven <Text as="u">ieder</Text> 1x de inzet, die
            inzet wordt verdeeld onder de winnaars
        </>
    ),
});
WAGER_EXPLAINED.set(WagerType.single, {
    title: 'Enkel',
    explained: (
        <>
            Alle verliezers <Text as="u">samen</Text> geven 1x de inzet, die
            inzet wordt verdeeld onder de winnaars
        </>
    ),
});
WAGER_EXPLAINED.set(WagerType.oneSided, {
    title: 'Eenzijdig',
    explained: (
        <>
            Als <Text as="u">jij (maker van het wedje)</Text> verliest, geef jij
            1x de inzet, die inzet wordt verdeeld onder de winnaars
        </>
    ),
});

export const LOCAL_STORAGE_TOKEN_KEY = 'token';
export const LOCAL_STORAGE_WEDJE_KEY = 'wedje';
