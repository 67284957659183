import React, { FunctionComponent } from 'react';
import { Box, Link, List, ListItem, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { Wedje } from '../../types';
import DatePicker from '../global/DatePicker';

interface Props {
    wedje: Wedje;
    onChange: (newWedje: Wedje) => void;
}

const WedjeFormEndDate: FunctionComponent<Props> = ({ wedje, onChange }) => {
    let curDate = new Date(wedje.end * 1000);
    const handleChange = (newDate: Date) => {
        onChange({
            ...wedje,
            end: Math.round(newDate.getTime() / 1000),
        });
    };

    const handleQuickDate = (date: Date) => () => {
        handleChange(date);
    };
    if (wedje.end < 1) {
        curDate = moment().add(2, 'weeks').toDate();
        handleQuickDate(curDate);
    }

    const quickDate = (label: string, date: Date) => (
        <ListItem>
            <Link
                fontSize="sm"
                color="wedje.200"
                onClick={handleQuickDate(date)}
            >
                {label}
            </Link>
        </ListItem>
    );

    return (
        <>
            <Text fontSize="xl" color="wedje.300">
                Einddatum
            </Text>
            <Text fontSize="sm" color="wedje2.300" paddingTop={4}>
                Kies de verwachte einddatum{' '}
                <Text as="span" color="wedje2.500" fontSize="sm">
                    *
                </Text>
            </Text>
            <Box display="flex">
                <Box position="relative" height="300px" width="250px">
                    <Box marginLeft="-0.5em" position="absolute" height="200px">
                        <DatePicker onChange={handleChange} value={curDate} />
                    </Box>
                    <Text paddingTop="300px" size="xl" color="wedje2.400">
                        <Text color="wedje2.200" as="span">
                            Gekozen datum:{' '}
                        </Text>
                        {moment(curDate).format('L')}
                    </Text>
                </Box>
                <Box>
                    <Stack
                        spacing={0}
                        width="120px"
                        alignItems="left"
                        textAlign="right"
                    >
                        <Text fontSize="md" color="wedje2.50" paddingTop={4}>
                            Snelle datums
                        </Text>
                        <List spacing={3} paddingTop={2}>
                            {quickDate(
                                'morgen',
                                moment().add(1, 'day').toDate()
                            )}
                            {quickDate(
                                'volgende week',
                                moment().add(1, 'weeks').toDate()
                            )}
                            {quickDate(
                                '+2 weken',
                                moment().add(2, 'weeks').toDate()
                            )}
                            {quickDate(
                                '+1 maand',
                                moment().add(1, 'month').toDate()
                            )}
                            {quickDate(
                                '+3 maanden',
                                moment().add(3, 'month').toDate()
                            )}
                            {quickDate(
                                '+6 maanden',
                                moment().add(6, 'month').toDate()
                            )}
                            {quickDate(
                                '+1 jaar',
                                moment().add(1, 'year').toDate()
                            )}
                            {quickDate(
                                '+2 jaar',
                                moment().add(1, 'year').toDate()
                            )}
                        </List>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};

export default WedjeFormEndDate;
