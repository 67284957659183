import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Checkbox, Input, Link, Text } from '@chakra-ui/react';
import { RouterLink, Wedje } from '../../types';
import { AuthProviderContext } from '../../AuthProvider';

interface Props {
    wedje: Wedje;
    onChange: (newWedje: Wedje) => void;
}

const WedjeFormOwner: FunctionComponent<Props> = ({ wedje, onChange }) => {
    const { isAuthenticated, user } = useContext(AuthProviderContext);

    const handleUpdateCheckbox = (event: any) => {
        onChange({
            ...wedje,
            updateByEmail: event.target.checked,
        });
    };

    useEffect(() => {
        if (isAuthenticated && user && user.id) {
            onChange({
                ...wedje,
                ownerUserId: user.id,
                ownerEmail: '',
            });
        } else {
            onChange({
                ...wedje,
                ownerUserId: 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user]);

    const handleChangeEmail = (event: any) => {
        onChange({
            ...wedje,
            ownerUserId: 0,
            ownerEmail: event.target.value,
            updateByEmail: true,
        });
    };

    return (
        <>
            <Text fontSize="xl" color="wedje.300" paddingBottom={4}>
                Updates
            </Text>
            {isAuthenticated ? (
                <>
                    <Checkbox
                        paddingTop={2}
                        onChange={handleUpdateCheckbox}
                        defaultIsChecked
                    >
                        <Text fontSize="sm">
                            Stuur me een e-mail als het wedje afloopt
                        </Text>
                    </Checkbox>
                </>
            ) : (
                <>
                    <Text fontSize="sm">
                        Om je een update te sturen als het wedje afloopt hebben
                        we je e-mailadres nodig
                    </Text>
                    <Input
                        marginY={4}
                        onChange={handleChangeEmail}
                        placeholder="email@voorbeeld.nl"
                        value={wedje.ownerEmail}
                    />
                    <Text fontSize="sm">
                        Je kunt ook{' '}
                        <Link as={RouterLink} color="wedje.500" to="/register">
                            registeren
                        </Link>{' '}
                        of{' '}
                        <Link as={RouterLink} color="wedje.500" to="/login">
                            inloggen
                        </Link>{' '}
                        om al je wedjes in een overzicht te zien
                    </Text>
                    <Text fontSize="sm" color="wedje2.400" paddingTop={2}>
                        Je e-mailadres wordt voor niets anders gebruikt dan het
                        sturen van een &eacute;&eacute;nmalige herinnering aan
                        je weddenschap
                    </Text>
                </>
            )}
        </>
    );
};
export default WedjeFormOwner;
