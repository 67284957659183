import React, { FunctionComponent, ReactElement } from 'react';
import { Box, SimpleGrid, Stack } from '@chakra-ui/react';

type Props = {
    leftColumn: ReactElement;
};

const TwoColPage: FunctionComponent<Props> = ({ leftColumn, children }) => (
    <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        spacing={4}
        padding={4}
        paddingTop={8}
        maxWidth={900}
    >
        <Box>
            <Stack spacing={0} width="100%" alignItems="center">
                {leftColumn}
            </Stack>
        </Box>
        <Box>
            <Stack spacing={0} width="100%" alignItems="center" padding={4}>
                {children}
            </Stack>
        </Box>
    </SimpleGrid>
);

export default TwoColPage;
