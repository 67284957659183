import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    Box,
    Button,
    Center,
    Divider,
    Fade,
    Flex,
    HStack,
    Progress,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import { Redirect } from 'react-router-dom';
import { Wedje } from '../../types';
import { LOCAL_STORAGE_WEDJE_KEY, WagerType, WedjeType } from '../../constants';
import WedjeFormSubject from './WedjeFormSubject';
import WedjeFormAnswer from './WedjeFormAnswer';
import WedjeFormWager from './WedjeFormWager';
import WedjeFormEndDate from './WedjeFormEndDate';
import WedjeFormOwner from './WedjeFormOwner';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import {
    stringToWedjeFormState,
    wedjeFormStateToString,
} from '../../utils/WedjeHelper';
import ApiHelper from '../../utils/ApiHelper';
import { AuthProviderContext } from '../../AuthProvider';

export const initialWedje: Wedje = {
    type: WedjeType.oneOnOne,
    subject: '',
    answer: [
        {
            answer: '',
        },
        {
            answer: '',
        },
    ],
    wager: '',
    wagerType: WagerType.pot,
    end: 0,
    updateByEmail: true,
    ownerUserId: 0,
    ownerName: '',
    ownerEmail: '',
};

type Props = {
    quickWedje: Partial<Wedje> | null;
};

const WedjeForm: FunctionComponent<Props> = ({ quickWedje }) => {
    const [step, setStep] = useState(0);
    const [wedje, setWedje] = useState<Wedje>(initialWedje);
    const [wedjeIsDirty, setWedjeIsDirty] = useState(false);
    const [wedjeIsQuick, setWedjeIsQuick] = useState(false);
    const [wedjeIsSaving, setWedjeIsSaving] = useState(false);
    const [wedjeError, setWedjeError] = useState('');
    const totalSteps = 6;
    const saveAfterStep = 4;
    const toast = useToast();
    const { isAuthenticated, user } = useContext(AuthProviderContext);

    const [localWedje, setLocalWedje] = useLocalStorage(
        LOCAL_STORAGE_WEDJE_KEY,
        ''
    );

    useEffect(() => {
        if (localWedje.length > 0) {
            const wedjeState = stringToWedjeFormState(localWedje);
            if (wedjeState) {
                setWedje(wedjeState.wedje);
                setStep(wedjeState.step);
            }
        }
    }, [localWedje]);

    const saveWedje = () => {
        setWedjeIsSaving(true);
        const wedjeWithInjectedUserIds = wedje;
        // Since the user can login at any time in the process we inject userids on the correct spots just before saving
        if (isAuthenticated && user) {
            wedje.ownerUserId = user.id;
            wedje.answer[0] = {
                ...wedje.answer[0],
                userId: user.id,
            };
        }
        let updatedWedje = wedje;
        // Save wedje
        ApiHelper.addOrUpdateWedje(wedjeWithInjectedUserIds)
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: 'Er gaat iets mis',
                        description: String(res?.json?.message) || String(res),
                        status: 'error',
                    });
                    setWedjeIsSaving(false);
                } else {
                    const wedjeId = Number(res?.json?.id);
                    const shareCode = String(res?.json?.share_code);
                    updatedWedje = {
                        ...updatedWedje,
                        id: wedjeId,
                        shareCode,
                    };
                    return Promise.all(
                        wedje.answer.map((answer) =>
                            ApiHelper.addOrUpdateAnswer(
                                updatedWedje.id!,
                                answer
                            )
                        )
                    );
                }
                return null;
            })
            .then((resAr) => {
                if (resAr) {
                    resAr.forEach((res, index) => {
                        if (res.status !== 200) {
                            toast({
                                title: 'Er gaat iets mis',
                                description:
                                    String(res?.json?.message) || String(res),
                                status: 'error',
                            });
                            setWedjeIsSaving(false);
                        } else {
                            const answers = wedje.answer;
                            answers[index].id = Number(res?.json?.id);
                            updatedWedje = {
                                ...updatedWedje,
                                answer: answers,
                            };
                            setWedjeIsSaving(false);
                            setWedje(updatedWedje);
                            setLocalWedje(
                                wedjeFormStateToString(updatedWedje, step + 1)
                            );
                            setStep(step + 1);
                        }
                    });
                }
                return null;
            })
            .catch((error) => {
                console.error('error?', error);
            });
    };

    const handleUpdateWedje = useCallback((newWedje: Wedje) => {
        setWedjeIsDirty(true);
        setWedjeIsQuick(false);
        setWedje(newWedje);
    }, []);

    // Takes quickWedje and overwrites where possible
    if (!wedjeIsDirty && !wedjeIsQuick && quickWedje !== null) {
        setWedje({
            ...wedje,
            subject:
                wedje.subject !== ''
                    ? wedje.subject
                    : quickWedje?.subject || '',
            answer:
                wedje.answer?.length > 0 && wedje.answer[0].answer !== ''
                    ? wedje.answer
                    : quickWedje?.answer || [],
            end: wedje.end > 0 ? wedje.end : quickWedje?.end || 0,
        });
        setWedjeIsQuick(true);
    }

    let stepForm: ReactNode;
    let stepSchema: yup.ObjectSchema<any>;
    switch (step) {
        case 0:
            stepForm = (
                <WedjeFormSubject wedje={wedje} onChange={handleUpdateWedje} />
            );
            stepSchema = yup.object().shape({
                subject: yup.string().required('Vul het wedje in'),
                name: yup.string(),
            });
            break;
        case 1:
            stepForm = (
                <WedjeFormAnswer wedje={wedje} onChange={handleUpdateWedje} />
            );
            stepSchema = yup.object().shape({
                answer: yup.array().of(
                    yup.object().shape({
                        provideLater: yup.boolean(),
                        answer: yup.string().when('provideLater', {
                            is: (val: any) => !!val,
                            then: yup.string(),
                            otherwise: yup
                                .string()
                                .required('Vul de voorspelling(en) in'),
                        }),
                    })
                ),
            });
            break;
        case 2:
            stepForm = (
                <WedjeFormWager wedje={wedje} onChange={handleUpdateWedje} />
            );
            stepSchema = yup.object().shape({
                wager: yup.string().required('Vul de inzet in'),
                wagerType: yup.string().required('Kies een verdelingsmethode'),
            });
            break;
        case 3:
            stepForm = (
                <WedjeFormEndDate wedje={wedje} onChange={handleUpdateWedje} />
            );
            stepSchema = yup.object().shape({
                end: yup.number().required('Kies een einddatum'),
            });
            break;
        case 4:
            stepForm = (
                <WedjeFormOwner wedje={wedje} onChange={handleUpdateWedje} />
            );
            stepSchema = yup.object().shape({
                ownerUserId: yup.number(),
                ownerEmail: yup.string().when('ownerUserId', {
                    is: (val: any) => val === 0,
                    then: yup
                        .string()
                        .email()
                        .required('Vul een geldig e-mailadres in'),
                    otherwise: yup.string(),
                }),
            });
            break;
        case 5:
            stepForm = <Redirect to={`/share/${wedje.shareCode}`} />;
            break;
        default:
            stepForm = <Text>Onbekende stap? {step}</Text>;
            break;
    }

    const handleNextStep = () => {
        if (stepSchema) {
            stepSchema
                .validate(wedje)
                .then(() => {
                    setWedjeError('');
                    if (step === saveAfterStep) {
                        saveWedje();
                    } else {
                        setLocalWedje(wedjeFormStateToString(wedje, step + 1));
                        setStep(step + 1);
                    }
                    return '';
                })
                .catch((err) => {
                    setWedjeError(err.errors?.join(',') || '');
                });
        } else {
            setStep(step + 1);
            setLocalWedje(wedjeFormStateToString(wedje, step + 1));
        }
    };
    const handlePreviousStep = () => {
        setLocalWedje(wedjeFormStateToString(wedje, step - 1));
        setStep(step - 1);
    };

    if (wedjeIsSaving) {
        return (
            <Box
                minWidth="360px"
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
                bg="backgrounds.300"
                width="100%"
            >
                <Box paddingX={8} paddingTop={4} paddingBottom={6} minH={280}>
                    <Text fontSize="xl" color="wedje.300">
                        Opslaan
                    </Text>
                    <Text>Wedje wordt opgeslagen</Text>
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="200px"
                    >
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            color="wedje.500"
                            size="xl"
                        />
                    </Flex>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            minWidth="360px"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
            bg="backgrounds.300"
            width="100%"
        >
            <Box paddingX={8} paddingTop={4} paddingBottom={6} minH={280}>
                {stepForm}
            </Box>
            <Fade in={wedjeError !== ''}>
                <Box width="100%" position="relative">
                    <Box
                        top="-24px"
                        height="24px"
                        bg="wedje.300"
                        color="wedje2.700"
                        width="100%"
                        overflow="hidden"
                        textAlign="center"
                        marginBottom="2px"
                        position="absolute"
                    >
                        {wedjeError}
                    </Box>
                </Box>
            </Fade>
            {step < totalSteps - 1 && (
                <>
                    <Progress
                        colorScheme="wedje"
                        size="xs"
                        hasStripe
                        value={((step + 1) / totalSteps) * 100}
                    />
                    <HStack>
                        <Button
                            aria-label="vorige"
                            variant="ghost"
                            isFullWidth
                            borderRadius="0px"
                            leftIcon={<HiOutlineArrowLeft />}
                            disabled={step === 0}
                            onClick={handlePreviousStep}
                        >
                            Vorige stap
                        </Button>
                        <Center height="30px">
                            <Divider orientation="vertical" />
                        </Center>
                        <Button
                            aria-label="volgende"
                            variant="ghost"
                            isFullWidth
                            borderRadius="0px"
                            rightIcon={<HiOutlineArrowRight />}
                            onClick={handleNextStep}
                            disabled={step >= totalSteps - 1}
                        >
                            Volgende stap
                        </Button>
                    </HStack>
                </>
            )}
        </Box>
    );
};

export default WedjeForm;
