import React, { FunctionComponent, useContext } from 'react';
import { Input, Link, Text } from '@chakra-ui/react';
import { RouterLink, Wedje } from '../../types';
import { AuthProviderContext } from '../../AuthProvider';

interface Props {
    wedje: Wedje;
    onChange: (newWedje: Wedje) => void;
}

const WedjeFormSubject: FunctionComponent<Props> = ({ wedje, onChange }) => {
    const { isAuthenticated, user } = useContext(AuthProviderContext);

    const handleChangeSubject = (event: any) => {
        onChange({
            ...wedje,
            subject: event.target.value,
        });
    };

    const handleChangeName = (event: any) => {
        onChange({
            ...wedje,
            ownerName: event.target.value,
        });
    };

    return (
        <>
            <Text fontSize="xl" color="wedje.300">
                Wedje leggen
            </Text>
            <Text fontSize="sm" color="wedje2.300" paddingTop={4}>
                Wat is het wedje?{' '}
                <Text as="span" color="wedje2.500" fontSize="sm">
                    *
                </Text>
            </Text>
            <Input
                marginY={2}
                onChange={handleChangeSubject}
                placeholder="Wie wordt er kampioen dit jaar?"
                value={wedje.subject}
            />
            {isAuthenticated ? (
                <Text fontSize="sm" color="wedje2.300">
                    Welkom terug {user?.name}. Als ingelogde gebruiker kun je
                    een{' '}
                    <Link as={RouterLink} color="wedje.500" to="/overview">
                        overzicht van je wedjes
                    </Link>{' '}
                    bekijken
                </Text>
            ) : (
                <>
                    <Text fontSize="sm" color="wedje2.300" paddingTop={2}>
                        Wat is je naam?
                    </Text>
                    <Input
                        marginY={2}
                        onChange={handleChangeName}
                        placeholder="Naam"
                        value={wedje.ownerName}
                    />
                    <Text fontSize="sm" paddingTop={2} color="wedje2.300">
                        Als je{' '}
                        <Link as={RouterLink} color="wedje.500" to="/register">
                            registreert
                        </Link>{' '}
                        of{' '}
                        <Link as={RouterLink} color="wedje.500" to="/login">
                            inlogt
                        </Link>{' '}
                        wordt je naam automatisch ingevuld
                    </Text>
                </>
            )}
        </>
    );
};

export default WedjeFormSubject;
