import React, { FunctionComponent } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Link,
    Text,
    useToast,
} from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { Field, FieldProps, Form, Formik } from 'formik';
import ApiHelper from '../../utils/ApiHelper';
import FullCenterForm from '../global/FullCenterForm';
import { RouterLink } from '../../types';

type FormValues = {
    name: string;
    email: string;
    password: string;
    passwordConfirm: string;
};

const initialValues: FormValues = {
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
};

const validationSchema = yup.object().shape({
    name: yup.string().required('Vul je naam in'),
    email: yup
        .string()
        .email('Vul een geldig e-mailadres in')
        .required('Vul je e-mailadres in'),
    password: yup
        .string()
        .min(8, 'Wachtwoord is te kort, moet minimaal 8 karakters zijn')
        .required('Vul je wachtwoord in'),
    passwordConfirm: yup
        .string()
        .oneOf(
            [yup.ref('password'), null],
            'Wachtwoorden moeten hetzelfde zijn'
        ),
});

const RegistrationForm: FunctionComponent = () => {
    const toast = useToast();
    const referrer = document.referrer.replace(/^.*\/\/[^/]+/, '') ?? '/';

    const submitHandler = (values: FormValues) =>
        ApiHelper.register(values.name, values.email, values.password)
            .then((res) => {
                if (res.status !== 201) {
                    let error = '';
                    if (res.json) {
                        Object.values(res.json).forEach((value) => {
                            error = `${error} ${value}`;
                        });
                    }
                    toast({
                        title: `Er gaat iets mis`,
                        description: error,
                    });
                } else {
                    toast({
                        title: `Welkom ${values.name}, bedankt voor je registratie`,
                        status: 'success',
                    });
                }
                return res;
            })
            .catch((error: string) => {
                toast({
                    title: 'Er gaat iets mis',
                    description: error.toString(),
                    status: 'error',
                });
            });

    if (ApiHelper.isAuthenticated) {
        return <Redirect to={referrer} />;
    }

    return (
        <FullCenterForm
            title="Registeren"
            subtitle="Vaker wedjes maken? Bestaande wedjes koppelen? Status van wedjes inzien? Maak een account aan"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={submitHandler}
                validationSchema={validationSchema}
            >
                {(props) => (
                    <Form>
                        <Field name="name">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="name">Naam</FormLabel>
                                    <Input
                                        {...field}
                                        id="name"
                                        placeholder="Naam"
                                        size="lg"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="email">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="email">
                                        E-mailadres
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="email"
                                        placeholder="test@test.com"
                                        size="lg"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="password">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                    paddingBottom={4}
                                >
                                    <FormLabel htmlFor="password">
                                        Wachtwoord
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="password"
                                        placeholder="*********"
                                        size="lg"
                                        type="password"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="passwordConfirm">
                            {({ field, meta }: FieldProps) => (
                                <FormControl
                                    isInvalid={meta.touched && !!meta.error}
                                >
                                    <FormLabel htmlFor="passwordConfirm">
                                        Wachtwoord{' '}
                                        <Text
                                            as="span"
                                            color="wedje2.300"
                                            fontSize="sm"
                                        >
                                            (Ter controle)
                                        </Text>
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        id="passwordConfirm"
                                        placeholder="*********"
                                        size="lg"
                                        type="password"
                                    />
                                    <FormErrorMessage>
                                        {meta.error}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Button
                            isLoading={props.isSubmitting}
                            disabled={!(props.isValid && props.dirty)}
                            type="submit"
                            width="full"
                            mt={4}
                        >
                            {props.isSubmitting ? (
                                <CircularProgress
                                    isIndeterminate
                                    size="24px"
                                    color="wedje.500"
                                />
                            ) : (
                                'Registreer'
                            )}
                        </Button>
                        <Box textAlign="center" paddingTop={3}>
                            <Link
                                color="wedje.500"
                                as={RouterLink}
                                to="/forgot-password"
                            >
                                Wachtwoord vergeten
                            </Link>
                        </Box>

                        <Box textAlign="center" paddingTop={5}>
                            <Link color="wedje.500" as={RouterLink} to="/login">
                                Al een account? Klik hier om in te loggen
                            </Link>
                        </Box>
                    </Form>
                )}
            </Formik>
        </FullCenterForm>
    );
};

export default RegistrationForm;
