import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Spinner,
    Text,
} from '@chakra-ui/react';
import { GrShareOption } from 'react-icons/gr';
import { useParams } from 'react-router';
import { RouterLink, Wedje } from '../../types';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { LOCAL_STORAGE_WEDJE_KEY } from '../../constants';
import ApiHelper from '../../utils/ApiHelper';
import { wedjeFromJSON } from '../../utils/WedjeHelper';
import { AuthProviderContext } from '../../AuthProvider';

const WedjeShareForm: FunctionComponent = () => {
    const { shareCode } = useParams<any>();
    const { isAuthenticated, user } = useContext(AuthProviderContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [wedje, setWedje] = useState<Wedje | null>(null);

    const [messageTitle, setMessageTitle] = useState<string>('');
    const [messageText, setMessageText] = useState<string>('');

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.getWedjeByShareCode(shareCode)
            .then((res) => {
                setIsLoading(false);
                if (res.status === 200 && res.json) {
                    const parsedWedje = wedjeFromJSON(res.json);
                    setWedje(parsedWedje);
                } else {
                    setMessageTitle('Wedje niet gevonden?');
                    setMessageText(
                        'Het wedje kon niet gevonden worden? Controleer de link, of kopieer / plak deze handmatig. Het kan ook zijn dat het wedje is verwijderd'
                    );
                }
                return res;
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
                setMessageTitle('Wedje niet gevonden?');
                setMessageText(
                    'Het wedje kon niet gevonden worden? Controleer de link, of kopieer / plak deze handmatig. Het kan ook zijn dat het wedje is verwijderd'
                );
            });
    }, [isAuthenticated, shareCode, user]);

    let isShareCapable = false;

    const wedjeUrl = `https://wedje.nu/w/${wedje?.shareCode}`;

    // @ts-ignore
    if (navigator.canShare) {
        isShareCapable = true;
    }

    const handleShare = () => {
        navigator
            .share({
                title: 'wedje.nu',
                text: `Wedje leggen? ${wedje?.subject}`,
                url: wedjeUrl,
            })
            .then(() => {
                setLocalWedje('');
                return true;
            })
            .catch((error) => console.log('Error sharing', error));
    };

    const handleCopyText = () => {
        setLocalWedje('');
        navigator.clipboard.writeText(wedjeUrl);
    };

    const [, setLocalWedje] = useLocalStorage(LOCAL_STORAGE_WEDJE_KEY, '');
    const handleClearWedje = () => {
        setLocalWedje('');
        window.location.href = '/';
    };

    if (isLoading) {
        return (
            <>
                <Text fontSize="xl" color="wedje.300">
                    Laden
                </Text>
                <Text>Wedje wordt opgehaald</Text>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="200px"
                >
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        color="wedje.500"
                        size="xl"
                    />
                </Flex>
            </>
        );
    }

    if (messageText !== '') {
        return (
            <>
                <Text fontSize="xl" color="wedje.300">
                    {messageTitle}
                </Text>
                <Text fontSize="md" paddingTop={4}>
                    {messageText}
                </Text>
            </>
        );
    }

    return (
        <>
            <Text fontSize="xl" color="wedje.300">
                Wedje delen
            </Text>
            <Text fontSize="sm" paddingTop={2}>
                Gebruik de opties hieronder om het wedje te delen met je
                vrienden. Als ze de link bezoeken kunnen ze de weddenschap met
                je aangaan
            </Text>
            {isShareCapable && (
                <Box paddingTop={4}>
                    <Button
                        leftIcon={<GrShareOption />}
                        colorScheme="wedje"
                        variant="solid"
                        isFullWidth
                        onClick={handleShare}
                    >
                        Deel met je vrienden
                    </Button>
                </Box>
            )}
            <Box paddingTop={4}>
                <Text fontSize="sm" color="wedje2.300">
                    Handmatig delen
                </Text>
                <InputGroup size="md">
                    <Input pr="4.5rem" type="text" value={wedjeUrl} readOnly />
                    <InputRightElement width="7.4rem">
                        <Button h="1.75rem" size="sm" onClick={handleCopyText}>
                            Link kopieren
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </Box>
            <Text fontSize="sm" paddingTop={8} color="wedje2.300">
                Je kunt ook{' '}
                <Link onClick={handleClearWedje} color="wedje.500">
                    een nieuw wedje starten
                </Link>
                {isAuthenticated && (
                    <>
                        {' '}
                        of een{' '}
                        <Link as={RouterLink} color="wedje.500" to="/overview">
                            overzicht van je wedjes
                        </Link>{' '}
                        bekijken
                    </>
                )}
            </Text>
        </>
    );
};

export default WedjeShareForm;
