import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    GridItem,
    Heading,
    Skeleton,
    Stack,
    Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { GrShareOption } from 'react-icons/gr';
import { AuthProviderContext } from '../../AuthProvider';
import ApiHelper from '../../utils/ApiHelper';
import { wedjeFromJSON } from '../../utils/WedjeHelper';
import { RouterLink, Wedje } from '../../types';

const WedjeOverview: FunctionComponent = () => {
    const { isAuthenticated } = useContext(AuthProviderContext);
    const [wedjeList, setWedjeList] = useState<Wedje[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.getWedjes()
            .then((res) => {
                if (res) {
                    setIsLoading(false);
                    if (res.status === 200) {
                        if (Array.isArray(res.json)) {
                            const newList: Wedje[] = [];
                            res.json.forEach((w) => {
                                const j = wedjeFromJSON(w);
                                if (j) {
                                    newList.push(j);
                                }
                            });
                            setWedjeList(newList);
                        }
                    }
                }
                return res;
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    if (!isAuthenticated) {
        return null;
    }

    if (isLoading) {
        <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
        </Stack>;
    }

    return (
        <>
            <Stack
                spacing={4}
                as={Container}
                maxW="3xl"
                textAlign="center"
                marginTop={8}
            >
                <Heading fontSize="3xl">Jouw wedjes</Heading>
                {wedjeList.map((wedje) => (
                    <Box
                        key={`w-${wedje.id}`}
                        minWidth="360px"
                        borderWidth={1}
                        borderRadius={8}
                        boxShadow="lg"
                        bg="backgrounds.300"
                        width="100%"
                        marginTop={4}
                        paddingY={2}
                        paddingX={4}
                        textAlign="left"
                    >
                        <Grid
                            templateRows="repeat(2, 1fr)"
                            templateColumns="repeat(6, 1fr)"
                            gap={1}
                        >
                            <GridItem colSpan={4} p={1}>
                                <Text fontSize="xl" color="wedje.500">
                                    {wedje.subject}
                                </Text>
                            </GridItem>
                            <GridItem rowSpan={2} colSpan={2} p={1}>
                                {wedje.answer.find((a) => !a.locked) ? (
                                    <>
                                        <Text color="wedje2.300">
                                            Wacht op tegenstander
                                        </Text>
                                        <Box paddingTop={2}>
                                            <Button
                                                leftIcon={<GrShareOption />}
                                                colorScheme="wedje2"
                                                variant="solid"
                                                as={RouterLink}
                                                to={`/share/${wedje.shareCode}`}
                                                size="sm"
                                            >
                                                Delen
                                            </Button>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Text color="wedje2.300">
                                            Voorspellingen
                                        </Text>
                                        {wedje.answer.map((a) => (
                                            <Text key={`a-${a.id}`}>
                                                {a.name} - {a.answer}
                                            </Text>
                                        ))}
                                    </>
                                )}
                            </GridItem>
                            <GridItem colSpan={2} p={1}>
                                <Text color="wedje2.300" as="span">
                                    Inzet:
                                </Text>{' '}
                                {wedje.wager}
                            </GridItem>
                            <GridItem colSpan={2} p={1}>
                                <Text color="wedje2.300" as="span">
                                    Eind datum:
                                </Text>{' '}
                                {moment(wedje.end).format('L')}
                            </GridItem>
                        </Grid>
                    </Box>
                ))}
            </Stack>
        </>
    );
};

export default WedjeOverview;
